import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/article.css';

function ArticlePage() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchArticle() {
            try {
                const response = await axios.get(`https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/article/fetch/${id}`);
                setArticle(response.data);
            } catch (err) {
                setError('Failed to fetch the article. Please try again later.');
                console.error("Error fetching data:", err);
            } finally {
                setLoading(false);
            }
        }

        fetchArticle();
    }, [id]);

    if (loading) {
        return <div className="text-center">
            <p>Loading article...</p>
        </div>;
    }

    if (error) {
        return <div className="text-center" style={{ color: 'red' }}>
            <p>{error}</p>
        </div>;
    }

    if (!article) {
        return <div className="text-center">
            <p>No article found.</p>
        </div>;
    }

    return (
        <div className="container">
            <div className="article_Format">
                <div className="article-Img">
                    <img src={article.imageURL} className="post_img" alt={article.title} style={{ boxShadow: "3px 4px 6px 6px grey" }} />
                </div>
                <div className="article-Title">
                    <h1 className="display-3">{article.title}</h1>
                </div>
                <div className="article-Admin">
                    <h3 className='text-right'>
                        <small className="text-muted">- By {article.authorName}</small>
                    </h3>
                </div>
                <div className="article-body" dangerouslySetInnerHTML={{ __html: article.articleBody }}>
                    {/* Content is inserted via dangerouslySetInnerHTML */}
                </div>
            </div>
        </div>
    );
}

export default ArticlePage;
