import React, { useState, useEffect } from 'react'

import axios from "axios"
import background from '../image/competition/back.png';
// import winner from '../image/competition/back.png';
import '../css/competition.css'
function Competition() {
  const [winners, setWinner] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // competition
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/competition/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])

  // winners

  useEffect(() => {
    const getWinner = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/winners/fetch'
        );
        setWinner(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setWinner(null);
      } finally {
        setLoading(false);
      }
    }
    getWinner()
  }, [])
  let TextStyle = {
    fontWeight: "bold",
    fontFamily: "fantasy",
    margin: "3rem",
    color: "black",
    fontStyle: 'oblique',
    textDecoration: "underline",
    textDecorationStyle: "double",
    textDecorationColor: "darkslateblue",
    boxShadow: "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
  }
  let backgroundImg = {
    boxShadow: "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
    marginTop: "5px",
    marginBottom: "5px"

  }

  return (
    <>


      {loading && <div>A moment please...</div>}
      {error && (
        <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
      )}
      <div className="back my-3" style={backgroundImg}>
        <img src={background} alt="compete" style={{
          height: "8rem",
          width: "-webkit-fill-available"
        }
        } />
      </div>


      {/**/}


      {data &&
        data.map(({ _id, joiningLink, imageURL }) => (<div key={_id}  className="d-flex justify-content-center">

          <div className="card d-flex justify-content-center" style={{
            width: '40%', boxShadow:
              "1px 2px 2px hsl(220deg 60% 50% / 0.2) , 2px 4px 4px hsl(220deg 60% 50% / 0.2),4px 8px 8px hsl(220deg 60% 50% / 0.2),8px 16px 16px hsl(220deg 60% 50% / 0.2),16px 32px 32px hsl(220deg 60% 50% / 0.2)"
          }} >
            <img src={imageURL} className="card-img-top" alt="..." />
            <div className="card-body">
              <a href={joiningLink} className="btn btn-primary d-grid gap-2" style={{ fontWeight: "bold", color: "red", background: "#ddffaa" }}>Participate In Competition </a>
            </div>
          </div>
        </div>))}

      <p className="fs-1" style={TextStyle}>Past Competition And Its Winners:</p>
      <div className="container">

        {loading && <div>A moment please...</div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}
        <div className="gallery">
          <div className="row photos">
            {winners &&
              winners.map(({ _id, imageURL }) => (<div key={_id} className="col-sm-6 col-md-4 col-lg-3 item mx-2 my-2 justify-content-center" ><img className="img-fluid" alt="img" src={imageURL} /></div>))}

          </div>
        </div>
      </div>


    </>
  )
}

export default Competition
