import React, { useState, useEffect } from 'react'
import '../css/event.css'
import axios from "axios"
function UpcomingEvents() {
  // db ops
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/upcomingEvents/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])

  return (

    <>
      <div className='container'>
        <h1 className="text text-center" style={{ fontWeight: "bolder", color: "#59d19f", fontFamily: 'fantasy', marginTop: "3%", fontSize: "xx-large", marginBottom: "3%" }}>Upcoming Events</h1>
        {loading && <div>A moment please...</div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}
        {data &&
          data.map(({ _id, title, desc, imageURL, joiningLink }) => (<div key={_id} className="card mb-3 shadowDesign" style={{ border: "1px double red" }}>
            <div  className="row g-0">
              <div className="col-md-2">
                <img src={imageURL} className="img-fluid rounded-start" alt="..." />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">{title}</h5>
                  <p className="card-text">{desc}</p>
                  <button class="btn btn-primary my-3 my-2" type="button"><a href={joiningLink} style={{color:'red'}} rel="noreferrer" target="_blank"> CLick Here For Register</a></button>

                </div>
              </div>
            </div>
          </div>))}


        {/* Ongoing Event */}



        {/* successfull events */}


      </div>

    </>
  )
}

export default UpcomingEvents
