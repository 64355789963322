import React, { useState, useEffect } from 'react'
import axios from "axios"

import { useParams,Link } from 'react-router-dom';
function ElibCat() {
    const { catValue } = useParams();
  

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const getData = async () => {
      try {
        const response = await axios.get(
          `https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/book/fetch/cat/${catValue}`
        );
        setData(response.data);
        
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [catValue])

  return (
    <>
   <div className="container">
       <p className="heading text-center my-3">Category Searched Result</p>
       <Link to="/Elibrary" className="btn btn-success" type="button">Go Back To All Book </Link>
       {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday or there is no data !!SORRY!!</p></div>
        )}


<div className=" books row row-cols-1 row-cols-md-3 g-4 my-3">
{data &&
              data.map(({ _id, title,desc,bookImg,bookLink }) => (
                <div className="col h-100" key={_id}>
                <div className="card" style={{boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} >
            <img src={bookImg} className="card-img-top" alt="book img" style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"}}/>
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{desc}</p>
            </div>
            <div className="card-footer">
              <a href={bookLink} className="btn btn-primary" style={ {width: "100%" }}>
                <i className="bi bi-download" /> Download</a>
            </div>
          </div>  </div>
))}
</div>
<br/>
   </div>
    </>
  )
}

export default ElibCat
