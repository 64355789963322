import './css/newscard.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios'


function GroundWork() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // '/api/winner/fetch'
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const getData = async () => {

        try {
          const response = await axios.get(
            'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/groundwork/fetch/GroundImg'
          );
          setData(response.data.reverse());
          setError(null);

        } catch (err) {
          setError(err.message);
          setData(null);
        } finally {
          setLoading(false);
        }
      }
      getData();
    }
    return () => {
      isMounted = false;
    };
  }, [])


  return <>
    <div className="groundWorkGallery">
      {loading && <div>A moment please...</div>}
      {error && (
        <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
      )}
      {data &&
        data.map(({ _id, GroundImgURL }) => (
          <div key={_id} >
              <img src={GroundImgURL} alt="" />
          </div>
        ))}
    </div>
  </>
}

export default GroundWork
