import React, { useState, useEffect } from 'react'
import axios from "axios"
import paperBack from '../image/about section/paper.jpg'
import achivementBanner from '../image/about section/achivementsBanner.jpg'
import '../css/aboutsection.css'
function Achivements() {
  const paraStyle = {
    textAlign: 'justify',
    fontSize: 'large',
    color: 'black',
    fontFamily: 'Pacifico',
    margin: '0.5rem',
    backgroundImage: `url(${paperBack})`,
    boxShadow: 'rgb(6 24 44 / 40%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset'

  }
  const [award, setAward] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/partners/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])

  // achievements

  useEffect(() => {
    const getAward = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/achievements/fetch'
        );
        
        setAward(response.data.reverse());
        setError(null);
        
      } catch (err) {
        setError(err.message);
        setAward(null);
      } finally {
        setLoading(false);
      }
    }
    
    getAward()
  }, [])
  return (
    <div className="container">
      <div className='about_us' >

        <h3 style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '3.6rem', margin: '1rem 0.7rem 0.7rem 0.7rem', color: 'black', textAlign: 'center' }}>
          Who we are and why we are...?
        </h3>
        <p style={paraStyle}>
          <br />
          <li style={{
            listStyle: 'none',
            margin: '10px'
          }}>"Applied Forensic Research Sciences is registered under the Madhya Pradesh Society Registration Act 1973 and the Ministry of Micro, Small & Medium Enterprises (MSME), Government of India. The organization shares relevant knowledge on Forensic Science, Cyber Crime, Cyber Security, Forensic Laws, and all disciplines within Forensic Sciences, promoting awareness of crime prevention.
            <br />
            <br />
            The organization conducts beneficial international and national conferences, webinars, student skills development programs, national workshops, ambassador programs, and crash courses on various forensic science topics. AFRS delivers valuable knowledge to society regarding cyber crime and forensic science. Students and the general public can read and learn from the scientific research papers, books, and articles published by AFRS."
            <br />
            <br />
          </li>
        </p>
      </div>
      <br />
      <br />

      <div className="mission">
        <h3 style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '1.6rem', margin: '0.7', color: 'black' }}>Mission:</h3>
        <p style={paraStyle}>
          <li style={{
            listStyle: 'none',
            margin: '10px'
          }}>We aim to provide individuals with comprehensive knowledge of Forensic Science, raising awareness among the general public about the various types of crimes occurring globally. We seek to establish a collaborative framework that fosters interaction and idea-sharing among academics, industries, and other stakeholders. Additionally, we strive to instill ethical values and promote a scientific mindset in individuals

            <br /></li>
        </p>
      </div>
      <br />
      <div className="vision">
        <h3 style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '1.6rem', margin: '0.7', color: 'black' }}>Vision:</h3>
        <p style={paraStyle}>
          <li style={{
            listStyle: 'none',
            margin: '10px'
          }}>We provide high-quality resources, education, training, and consultancy to help individuals achieve their long- and short-term goals. This is our small contribution to the country and the world, addressing the critical shortage of Forensic Experts. During the pandemic, the AFRS team remained committed to this vision by raising awareness, providing study materials, and organizing events such as webinars and crash courses to ensure continuous learning for everyone.
            <br /></li>
        </p>
      </div>
      <br />
      <hr />

      <div className="partners">
        <section className="section section-default mt-none mb-none">
          <div className="container">
            <h2 className="mb-sm" style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '1.6rem', margin: '0.7rem', color: 'black', textAlign: 'center' }}>Our <strong>Partners</strong></h2>
            {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
            {error && (
              <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
            )}
            <strong>
              <div className="row">
                {data &&
                  data.map(({ _id, imageURL }) => (<div key={_id} className="col-sm-6 col-md-4 col-lg-3">
                    <div className="square-holder">
                      <img alt="" src={imageURL} />
                    </div>
                  </div>
                  ))}

              </div>
            </strong>
          </div>
        </section>
      </div>



      {/* achivements */}
      <div className="partners">
        <section className="section section-default mt-none mb-none">
          <div className="container">
            <div className='imgAchive' style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '1rem' }}>
              <img src={achivementBanner} style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} alt="Our Achievements" srcSet="" /></div>
            <strong>
              {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
              {error && (
                <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
              )}
              <div className="row">
                {award &&
                  award.map(({ _id, imageURL }) => (<div key={_id} className="col-sm-6 col-md-4 col-lg-3">
                    <div className="square-holder">
                      <img src={imageURL} alt="img"/>
                    </div>
                  </div>
                  ))}

              </div>
            </strong>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Achivements
