import React, { useEffect, useState } from 'react'
import '../css/profile.css'
import axios from "axios"
import memHeader from '../image/about section/members/memHeader.png'
import ThankYou from '../image/about section/members/thank.webp'
import '../css/mobileDesign.css'
function Members() {
 
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // '/api/winner/fetch'
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/members/fetch'
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])
  return (
    <>
      <div className='container' style={{}}>
        <img src={memHeader} style={{ margin: '1rem', boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" }} alt="Our Talented Members" srcSet="" />
        {loading && <div>A moment please...</div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}
        <ul id="hexGrid">
          {data &&
            data.map(({ _id, fullName, imageURL, designation }) => (<li key={_id} className="hex">
              <a className="hexIn" href="# ">
                <img src={imageURL} alt="members" style={{ border: "1px double red" }} />
                <h1 id="memName" style={{ color: 'red', fontFamily: 'fantasy' }}>{fullName}</h1>
                <p style={{ color: 'white' }}>{designation}</p>
              </a>
            </li>))}

        </ul>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={ThankYou} style={{ margin: '1rem', boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" }} alt="Our Talented Members" srcSet="" />

        </div>
        <br />
      </div>
    </>
  )
}

export default Members
