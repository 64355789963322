import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../css/article.css';

import headImg from '../image/study material/art.jpg';

function Articles() {
    const artImg = {
        display: "flex",
        justifyContent: "center",
        backgroundImage: "linear-gradient(to right, #050c12 , #12232d)",
        margin: "0.5rem",
        boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
    };

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchArticles() {
            try {
                const response = await axios.get('https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/articles/fetch');
                setArticles(response.data.reverse());
            } catch (err) {
                setError('Failed to fetch articles. Please try again later.');
                console.error("Error fetching data:", err);
            } finally {
                setLoading(false);
            }
        }

        fetchArticles();
    }, []);

    if (loading) {
        return <div style={{ textAlign: "center", marginTop: "50px" }}>
            <p>Loading articles...</p>
        </div>;
    }

    if (error) {
        return <div style={{ textAlign: "center", marginTop: "50px", color: "red" }}>
            <p>{error}</p>
        </div>;
    }

    return (
        <>
            <div className="artImg" style={artImg}>
                <img src={headImg} alt="Decorative header showing study materials" style={{ height: "175px" }} />
            </div>
            <div className="container">
                <div className="row row-cols-1 row-cols-md-3 g-4 my-3">
                    {articles.map(article => (
                        <div className="col" key={article._id}>
                            <Link to={{
                                pathname: `/ArticlePage/${article._id}`,
                                query: { id: article._id }
                            }} style={{ textDecoration: "none" }}>
                                <div className="card" id="articleCard">
                                    <img src={article.imageURL} className="card-img-top" alt={article.title} />
                                    <div className="card-body">
                                        <h5 className="card-title cdTitle">{article.title}</h5>
                                    </div>
                                    <div className="card-footer">
                                        <small className="text-muted">Written by: <strong>{article.authorName}</strong></small>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Articles;
