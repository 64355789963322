
import './App.css';
// import Navbar from './components/fixed components/Navbar';
import RouteUrls from './components/RouteUrls';
/* eslint-disable no-unused-vars */
const createError = require('http-errors');
function App() {
  return (
    <div className="App">
      
<RouteUrls/>
    </div>
  );
}

export default App;
