import React, { useState, useEffect } from 'react'
import axios from "axios"
import { Link } from 'react-router-dom'
import mcqTopic from '../image/study material/mcqTopic.png'
import '../css/mcq.css'
function Mcq() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/quiz/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])

  return (
    <>
      <div className="container">
        <div className="testList">
          <img src={mcqTopic} alt="MCQ TEST TOPICs" />
        </div>

        <div className="mcqTopicCards">
          {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
          {error && (
            <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
          )}

          <div className="row row-cols-1 row-cols-md-3 g-4">
            {data &&
              data.map(({ _id, title, topicImgUrl, noOfQuestions }) => (
                <div key={_id} className="col">
                  <Link to={{
                    pathname: `/McqTest/${_id}`,
                    query: { id: _id } // your data array of objects
                  }}> <div className="card" style={{ margin: "10px" }}>
                      <img src={topicImgUrl} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                      </div>

                      <div className="card-footer">
                        <p style={{ textAlign: "center" }}><strong>No. of questions:{noOfQuestions}</strong></p>
                      </div>
                    </div>
                  </Link>
                </div>))}

          </div>
        </div>
      </div>
    </>
  )
}

export default Mcq
