import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import headerBackground from '../image/study material/rd.png'
function ResearchPapers() {
  let header_Img = {
    background: "white",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
    overflow: "hidden",
    maxHeight: "170px"
  }

  let headDiv = {
    display: "flex",
    margin: "2rem",
    padding: "0px",
    justifyContent: "center"
  }

  // 

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // competition
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/researchpaper/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])
  return (
    <>

      <div className="container">
        <div className="header_image" style={headDiv}>
          <img src={headerBackground} style={header_Img} alt="Research Papers" />
        </div>
        {loading && <div>A moment please...</div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}

        {data &&
          data.map(({ _id, title, publicationURL, downloadURL }) => (
            <div className="accordion accordion-flush" key={_id} id="accordionFlushExample" style={{ border: "1px double #5a5425", margin: "5px 5px 8px", boxShadow: "rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px" }}>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    {title}
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body d-flex justify-content-between"><a href={publicationURL}><button className="btn btn-outline-danger">Publication Authority</button></a>
                    <a href={downloadURL} ><button className="btn btn-outline-success">Read or Download</button></a>
                  </div></div>
              </div>
            </div>))}

      </div>

    </>
  )
}

export default ResearchPapers
