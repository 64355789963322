import React, { useState, useEffect } from 'react'
import axios from "axios"
import { useParams } from 'react-router-dom';
import '../css/mcq.css'
import mcqTest from '../image/study material/mcqTest.png'
function McqTest() {


  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const getData = async () => {
      try {
        const response = await axios.get(
          `https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/quiztest/fetch/${id}`
        );
        setData(response.data);
        
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [id])
  // timer

  let iframeStyle = {
    display: "flex",
    justifyContent: "center",
    margin: "1rem"
  }
  return (
    <>
      <div className="container">
        <div className="testList">
          <img src={mcqTest} alt="MCQ TEST TOPICs" />
        </div>
        <div className="iframe-container" style={iframeStyle}>
          {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
          {error && (
            <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
          )}
          <iframe src={data.testLink} style={{ boxShadow: "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset", scrollbarColor: "red", scrollbarWidth: "thin" }} title="forensics" width={840} height={2171} frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe>
        </div>
      </div>

    </>
  )
}

export default McqTest
