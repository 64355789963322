import React, { useState, useEffect } from 'react'
import axios from "axios"
import storeImg from '../image/Store.png'
function Store() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const getData = async () => {
      try {
        const response = await axios.get(
          `https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/store/products/fetch`
        );
        setData(response.data);
       
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])
  return (
    <>
      <div className="banner">
        <img src={storeImg} alt="Store Banner" className="storeImage" />
      </div>
      <div className="container">
        {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}
        <div className="row row-cols-1 row-cols-md-3 g-4">

          {data &&
            data.map(({ _id, title, desc, productImgURL, buyLink }) => {

             return ( <div className="col" key={_id}>
                <div className="card h-100">
                  <img src={productImgURL} className="card-img-top" alt="img" />
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{desc}</p>
                  </div>
                  <div className="card-footer">
                   

                      <a href={buyLink} style={{ textDecoration: 'none' }}><button type="button" style={{ width: "100%" }} className="btn btn-outline-success">Buy Now</button></a>
                   
                  </div>
                </div>
              </div>)})}
        </div>
        <br /><br />
      </div>
    </>
  )
}

export default Store
