import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import elib from '../image/elib.jpg'
function Elibrary() {

  // fetch book 
  const [book, setBook] = useState(null);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/Category/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, []);

  // category fetch 

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/cat/book/fetch'
        );
        setBook(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setBook(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, []);


  // 

  
  return (
    <>
      <div className="container">

        <div className="imgContainer d-flex justify-content-center my-3">
          <img src={elib} alt="E_Library" style={{ width: "35%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px" }} className="src" />
        </div>
<div className="d-flex justify-content-center">
  <a href="https://t.me/afsjstudent"><i className="bi bi-telegram" /> &nbsp; Telegram Group</a>
</div>

        <div className="horLine d-flex justify-content-center">
          <hr style={{ width: "50%", height: "4px", color: "red", backgroundColor: "red" }} />
        </div>

        <div className="libContainer">
          <a className="btn btn-secondary" data-bs-toggle="offcanvas" style={{ "color": "black", fontSize: "large", fontFamily: "cursive", }} href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
            Categories &nbsp;
            <i className="bi bi-list" />


          </a>
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel" style={{ color: "red", fontWeight: "bold", fontFamily: "fantasy", fontSize: "22px" }}>Categories</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
              {error && (
                <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
              )}
                {data &&
                  data.map(({ _id, category,key }) => (
              <div className="d-grid gap-2  mx-auto my-3" key={_id}>
                    
                      
                    <Link to={{
                pathname: `/e/lib/category/catKey/${key}`,
                query: { catValue: key } // your data array of objects
              }} className="btn btn-outline-danger"  id="btnValue"   type="button" style={{ color: "black", fontFamily: "Pacifico", fontWeight: "500", letterSpacing: "0.1rem" }}>{category}</Link>
                   

              </div>
                  ))}

            </div>
          </div>


{/*  */}


<div className=" books row row-cols-1 row-cols-md-3 g-4 my-3">

            {loading && <div><p style={{ fontSize: "14px", color: "red", fontFamily: "fantasy", textAlign: "center" }}>A moment please...</p></div>}
            {error && (
              <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
            )}


{/*  */}
{book &&
              book.map(({ _id, title,desc,bookImg,bookLink }) => (
                <div className="col h-100"  key={_id}>
                <div className="card" style={{boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} >
            <img src={bookImg} className="card-img-top" alt="book img" style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"}}/>
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{desc}</p>
            </div>
            <div className="card-footer">
              <a href={bookLink} className="btn btn-primary" style={ {width: "100%" }}>
                <i className="bi bi-download" /> Download</a>
            </div>
          </div>  </div>
))}

      
      
</div>
<br/>
        </div>
      </div>
    </>
  )
}

export default Elibrary
