import React from "react";
import { Link } from "react-router-dom";
import "react-awesome-lightbox/build/style.css";
import "./css/home.css";
import NewsCard from "./NewsCard";
import GroundWork from "./GroundWork";
// import FooterPage from './fixed components/FooterPage'
// sliding 1
// import img1 from './image/slideShow/1.jpg'
// import img2 from './image/slideShow/2.jpg'
// import img3 from './image/slideShow/3.jpg'
// import img4 from './image/slideShow/4.jpg'
// import img5 from './image/slideShow/5.jpg'
// import img6 from './image/slideShow/6.jpg'
// import img7 from './image/slideShow/7.jpg'

import firstSlide from "./image/newSlideImg/first.webp";
import secondSlide from "./image/newSlideImg/QuestionedDocumentExamination.png";
import thirdSlide from "./image/newSlideImg/GeneralForensics.png";
import fourthSlide from "./image/newSlideImg/ForensicToxicology.png";
import fifthSlide from "./image/newSlideImg/ForensicPathology.png";
import sixthSlide from "./image/newSlideImg/ForensicEntomology.png";
import seventhSlide from "./image/newSlideImg/ForensicChemistry.png";
import eightSlide from "./image/newSlideImg/ForensicBiology.png";
import ninthSlide from "./image/newSlideImg/ForensicAnthropology.png";
import tenthSlide from "./image/newSlideImg/DigitalForensics.png";
import eleventhSlide from "./image/newSlideImg/Criminalistics.png";

// sliding 2
import {
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
} from "./image/exportImages";

function home() {
  // temp img

  //

  return (
    <>
      <div className="containerDesign shadow  ">
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
          placeholder="true"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active"
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <img
                src={firstSlide}
                className="d-block w-100"
                alt="First Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={secondSlide}
                className="d-block mx-auto w-100"
                alt="Second Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={thirdSlide}
                className="d-block w-100"
                alt="Third Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={fourthSlide}
                className="d-block w-100"
                alt="Fourth Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={fifthSlide}
                className="d-block w-100"
                alt="Fifth Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sixthSlide}
                className="d-block w-100"
                alt="Sixth Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={seventhSlide}
                className="d-block w-100"
                alt="Seventh Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={eightSlide}
                className="d-block w-100"
                alt="Eight Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={ninthSlide}
                className="d-block w-100"
                alt="Ninth Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={tenthSlide}
                className="d-block w-100"
                alt="Tenth Slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={eleventhSlide}
                className="d-block w-100"
                alt="Eleventh Slide"
              />
            </div>
            {/* Include more carousel items for the rest of your slides */}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* explore our society */}
      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />
      <div className="container card-design " placeholder="true" >
        <div className="explore-card " placeholder="true">
          <h3 className="title" style={{ fontWeight: "bolder" }}>
            Explore Our Society
          </h3>
          <p className="text my-2">
            Explore more in the field of Forensic Science. Develop organized
            skills of team management, decision making, improve communication
            skills, build self-confidence, productivity, leadership, etc. Have
            hands-on mini-projects and research field.
          </p>

          <Link to="/achievements" className="btn btn-danger my-3">
            Explore
          </Link>
        </div>
      </div>
      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />
      {/* why join us */}

      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />

      <div className="container random-img">
        <p
          className="text"
          style={{
            fontWeight: "bolder",
            color: "red",
            fontFamily: "fantasy",
            marginTop: "1%",
            fontSize: "2em",
            marginBottom: "1%",
          }}
        >
          Why Join us:-
        </p>

        <div id="gallery" placeholder="true">
          <img src={img8} className="d-block " alt="..." />
          <img src={img9} className="d-block " alt="..." />
          <img src={img10} className="d-block " alt="..." />
          <img src={img11} className="d-block " alt="..." />
          <img src={img12} className="d-block " alt="..." />
          <img src={img13} className="d-block " alt="..." />
          <img src={img14} className="d-block " alt="..." />
          <img src={img15} className="d-block " alt="..." />
          <img src={img16} className="d-block " alt="..." />
          <img src={img17} className="d-block " alt="..." />
          <img src={img18} className="d-block " alt="..." />
          <img src={img19} className="d-block " alt="..." />
          <img src={img20} className="d-block " alt="..." />
          <img src={img21} className="d-block " alt="..." />
          <img src={img22} className="d-block " alt="..." />
          <img src={img23} className="d-block " alt="..." />
          <img src={img24} className="d-block " alt="..." />
          <img src={img25} className="d-block " alt="..." />
        </div>
      </div>

      {/* news and announcement's */}

      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />
      <div className="container" style={{ background: "#faf8d6" }}>
        <p
          className="text"
          style={{
            fontWeight: "bolder",
            color: "brown",
            fontFamily: "fantasy",
            marginTop: "1%",
            fontSize: "2em",
            marginBottom: "1%",
          }}
        >
          News and Announcements:-
        </p>

        <NewsCard />
      </div>

      {/*  Ground Works And Activities */}
      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />
      <div className="container">
        <p
          className="text"
          style={{
            fontWeight: "bolder",
            color: "black",
            fontFamily: "fantasy",
            marginTop: "1%",
            fontSize: "2em",
            marginBottom: "1%",
          }}
        >
          Ground Works And Activities:-
        </p>
        <GroundWork />
      </div>
      <br/>

      {/* footer */}
      <hr
        style={{
          height: "2px",
          borderWidth: "0",
          color: "red",
          backgroundColor: "grey",
        }}
      />
    </>
  );
}

export default home;
