import React from 'react'
import {Link} from 'react-router-dom'
import notFound from '../image/study material/404.jpg'
function NotFoundPage() {
  return (
    <>
    <div className="container" style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
<img src={notFound} alt="404" style={{margin:"1px"}}/>
<h1 className="fs-1" style={{textAlign:"center",fontFamily:"fantasy",color:"red"}}>Oh! Looks Like You Forget Your Destination</h1>
    <button className ="btn btn-outline-success my-3"><Link to="/home">HOME</Link></button>
    </div>
    </>
  )
}

export default NotFoundPage