import React, { useState, useEffect } from 'react'
import '../css/event.css'
import axios from "axios"
function SuccessfulEvents() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/successfulEvents/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])
  return (
    <>
      <div className='container'>
        <h1 className="text text-center" style={{ fontWeight: "bolder", color: "red", fontFamily: 'fantasy', marginTop: "3%", fontSize: "xx-large", marginBottom: "3%" }}>successful Events</h1>
        {loading && <div>A moment please...</div>}
        {error && (
          <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
        )}
        {data &&
          data.map(({ _id, title, desc, imageURL }) => (
            <div key={_id} className="card mb-3 shadowDesign" style={{ border: "1px double green" }}>
              <div  className="row g-0">
                <div className="col-md-2">
                  <img src={imageURL} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{desc}</p>

                  </div>
                </div>
              </div>
            </div>))}


        {/* modal  */}

      </div>
    </>
  )
}

export default SuccessfulEvents
