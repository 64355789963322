import React from 'react'
import '../css/profile.css'
import headerImg from '../image/about section/founderHeader.png'
import president from '../image/about section/members/rakesh-mia.jpg'
import vice_president from '../image/about section/members/vijay-panchal.jpg'
import secretary from '../image/about section/members/AnushkaMukherjee.jpg'
import joint_secretory from '../image/about section/members/ShrutikaSingla.jpg'
import cashier from '../image/about section/members/AbhishekShah.jpg'
function founders() {
  return (
    <>
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
          <img src={headerImg} alt="Our Founders" id="headerImg" />
        </div>
        <div className='profileStyle'>
          <figure className="snip0045 red">
            <figcaption>
              <h2>Mr. Rakesh <span>Mia</span></h2>
              <p>B.Sc and M.Sc in Forensic Science</p>
              <p>"Let's take a step closer to make india better"</p>
              <div className="icons">
                <a href="https://www.facebook.com/rakesh.mia.144"><i className="bi bi-facebook" /></a>
                <a href="https://instagram.com/rakesh_mia?utm_medium=copy_link"><i className="bi bi-instagram" /></a>
                <a href="https://in.linkedin.com/in/rakesh-mia%F0%9F%87%AE%F0%9F%87%B3-2b9787182"><i className="bi bi-linkedin" /></a>
                <a href="https://twitter.com/rakeshmia2"><i className="bi bi-twitter" /></a>

              </div>
            </figcaption>
            <img src={president} alt="sample7" />
            <div className="position">President</div>
          </figure>
          <figure className="snip0045 green">
            <figcaption>
              <h2>Mr. Vijay <span>Panchal</span></h2>
              <p>B.Sc and M.Sc in Forensic Science</p>
              <p>"Let's take a step closer to make india better"</p>
              <div className="icons">
                <a href="https://www.facebook.com/profile.php?id=100017360178631"><i className="bi bi-facebook" /></a>
                <a href=" https://instagram.com/vijay_panchal9977?utm_medium=copy_link"><i className="bi bi-instagram" /></a>
                <a href="https://in.linkedin.com/in/vijay-panchal-8631261b0"><i className="bi bi-linkedin" /></a>
                <a href="https://mobile.twitter.com/vijay51652774"><i className="bi bi-twitter" /></a>

              </div>
            </figcaption>
            <img src={vice_president} alt="sample6" />
            <div className="position">Vice President</div>
          </figure>
        </div>





<div className="special-member">
<p className="heading my-3" id="splMem" >Special Members</p>

<div style={{display:'flex', justifyContent:'center',flexWrap:'wrap'}}>
{/*         <figure className="memCard">
          <div className="profile-image">
            <img src={secretary} alt="profile-sample2" />
          </div>
          <figcaption>
            <h3>Ms. Anushka Mukherjee</h3>
            <h5>Secretary</h5>
           
          </figcaption>
        </figure> */}
        <figure className="memCard">
          <div className="profile-image">
            <img src={joint_secretory} alt="profile-sample7" />
          </div>
          <figcaption>
            <h3>Ms. Shrutika Singla</h3>
            <h5>Joint Secretary</h5>
           
            
          </figcaption>
        </figure>


        <figure className="memCard">
          <div className="profile-image">
            <img src={cashier} alt="profile-sample6" />
          </div>
          <figcaption>
            <h3>Mr. Abhishek Shah</h3>
            <h5>Treasurer</h5>
            
          </figcaption>
        </figure>
      </div>

</div>
      </div>
    </>
  )
}

export default founders
