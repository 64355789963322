import React from 'react'
import './css/aboutsection.css'
import addImg from './image/about section/address.png'
import contactBanner from './image/about section/contactBanner.jpg'
function ContactUs() {

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center">

          <img src={contactBanner} className="img-fluid rounded-start d-flex " style={{ height: '255px', margin: '1rem' }} alt="..." />
        </div>
        <div className="card mb-3" >
          <div className="row g-0">
            <div className="col-md-4">
              <img src={addImg} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="InputName" className="form-label">Enter Full Name:</label>
                    <input type="text" className="form-control" id="InputName" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" />
                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="InputMsg" className="form-label">Enter Message:</label><br />
                    <textarea id="txt_message" rows={4} required="required" defaultValue={""} style={{ width: '100%' }} />
                  </div>
                  <button type="submit" className="btn btn-warning">Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs