import React from 'react'

function Policies() {
        return (
                <><div className="container my-3">
                        <h1 className="heading text-center"><strong>Copyright Disclaimer</strong></h1><br />
                        <br /><p>
                                Last updated June 11, 2021<br />


                                <strong>WEBSITE DISCLAIMER</strong><br />
                                <br />
                                The information provided by Applied Forensic Research Sciences (“we,” “us” or “our”) on appliedforensicresearchsciences.in (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
                                <br />
                                <strong>EXTERNAL LINKS DISCLAIMER</strong><br />

                                The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                                <br />
                                <strong>TESTIMONIALS DISCLAIMER</strong><br />

                                The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.

                                The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.

                                The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
                        </p>
                        <br />

                        <div className="disclaimer">
                                <br /><strong>Disclaimer for Applied Forensic Research Sciences</strong><br />
                                If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at Appliedforensicresearchsciences@gmail.com. Our Disclaimer was generated with the help of the Disclaimer Generator.
                                <br />
                                <strong>Disclaimers for AFRS</strong><br />
                                All the information on this website - appliedforensicresearchsciences.in - is published in good faith and for general information purpose only. appliedforensicresearchsciences does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (appliedforensicresearchsciences), is strictly at your own risk. appliedforensicresearchsciences will not be liable for any losses and/or damages in connection with the use of our website.

                                From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.

                                Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
                                <br /><br />
                                <strong>Consent</strong><br /><br />
                                By using our website, you hereby consent to our disclaimer and agree to its terms.
                                <br /><br />
                                <strong>Update</strong><br />
                                Should we update, amend or make any changes to this document, those changes will be prominently posted here.
                        </div><br /><br />
                </div></>
        )
}

export default Policies