import React, { useState, useEffect } from "react";
import "./css/newscard.css";
import Slider from "react-slick";
import axios from "axios";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NewsCard() {
  const config = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const ApiURI = "https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app";
  // eslint-disable-next-line no-unused-vars
  const [settings, setSettings] = useState(config);

  // db ops
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // '/api/winner/fetch'
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${ApiURI}/api/news/fetch/`);
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  // const onChangeCenterMode = e => {
  //   if (e.target.checked) {
  //     setSettings({
  //       ...config,
  //       centerMode: true,
  //       centerPadding: '50px'
  //     });
  //   } else {
  //     setSettings(config);
  //   }
  // }

  return (
    <div className="App1">
      {loading && <div>A moment please...</div>}
      {error && (
        <div>
          <p
            style={{
              fontSize: "20px",
              color: "red",
              fontFamily: "monospace",
              fontWeight: "900",
              textAlign: "center",
            }}
          >
            Oh! our data delivery boy on holiday !!SORRY!!
          </p>
        </div>
      )}

      <Slider {...settings}>
        {data &&
          data.map(({ _id, title, desc, imageURL }) => (
            <div key={_id} className="img-card">
              <img className="img" src={imageURL} alt="img" />
              <div className="card-body">
                <div className="card-title">{title}</div>
                <div className="card-text">{desc}</div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
export default NewsCard;
