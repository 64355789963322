import React, { useState, useEffect } from 'react'
import advBanner from '../image/about section/members/advisoryBanner.jpg'
import thankAdv from '../image/about section/members/thankAdv.png'
import axios from "axios"
function AdvisorCommittee() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // '/api/winner/fetch'
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/advisor/fetch'
        );
        setData(response.data.reverse());
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()
  }, [])
  return (
    <>
      <div className="container">
        <br />
        <div className="voltHead" style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}>
          <img src={advBanner} alt="our cool volunteers" />
        </div>
        <table style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }} className="table table-success table-striped table-hover">
          <thead>
            <tr>

              <th scope="col">Full Name</th>

            </tr>
          </thead>
          {loading && <div>A moment please...</div>}
          {error && (
            <div><p style={{ fontSize: "20px", color: "red", fontFamily: "monospace", fontWeight: "900", textAlign: "center" }}>Oh! our data delivery boy on holiday !!SORRY!!</p></div>
          )}
          <tbody>
            {data &&
              data.map(({ _id, fullName }) => (<tr key={_id}>

                <td>{fullName}</td>

              </tr>))}

          </tbody>
        </table>

        <div className="voltFoot" style={{ display: 'flex', justifyContent: 'space-around' }}>
          <img src={thankAdv} style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" }} alt="Thank you our cool volunteers" />
        </div>
      </div>
    </>
  )
}

export default AdvisorCommittee
