import React, { useState, useEffect } from "react";
import axios from "axios";
import voltHead from "../image/about section/members/voluntHead.jpg";
import voltFoot from "../image/about section/members/thankVolt.jpg";

function Volunteers() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [year, setYear] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [gender, setGender] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/volunteers/fetch"
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError("Oh! Our data delivery boy is on holiday. Sorry!");
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredData = data?.filter((item) => {
    const yearInt = parseInt(year);
    const lowercaseGender = gender ? gender.toLowerCase() : null;
    const lowercaseDesignation = designation ? designation.toLowerCase() : null;
    const lowercaseItemGender = item.gender ? item.gender.toLowerCase() : null;
    const lowercaseItemDesignation = item.designation
      ? item.designation.toLowerCase()
      : null;

    if (
      (!yearInt || item.joiningYear === yearInt) &&
      (!lowercaseGender || lowercaseItemGender === lowercaseGender) &&
      (!lowercaseDesignation ||
        lowercaseItemDesignation === lowercaseDesignation)
    ) {
      return true;
    }
    return false;
  });

  // Sort filteredData in descending order based on joiningYear
  const sortedData = filteredData?.sort((a, b) => b.joiningYear - a.joiningYear);

  return (
    <div className="container">
      <br />
      <div
        className="voltHead"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "8px",
        }}
      >
        <img
          src={voltHead}
          alt="Our cool volunteers"
          style={{ width: "280px" }}
        />
      </div>
      <div
        className="filterRow"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          margin: " 10px",
          gap: "5px",
        }}
      >
        <select
          id="gender"
          name="gender"
          value={gender || ""}
          onChange={(e) => setGender(e.target.value)}
          className="btn btn-secondary dropdown-toggle"
        >
          <option>Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Others</option>
        </select>
        <select
          id="designation"
          name="designation"
          value={designation || ""}
          onChange={(e) => setDesignation(e.target.value)}
          className="btn btn-secondary dropdown-toggle"
        >
          <option>Select Designation</option>
          <option value="Volunteer">Volunteers</option>
          <option value="Member">Members</option>
          <option value="Ambassador">Ambassador</option>
          <option value="Intern">Intern</option>
        </select>
        <select
          id="year"
          name="year"
          value={year || ""}
          onChange={(e) => setYear(e.target.value)}
          className="btn btn-secondary dropdown-toggle"
        >
          <option>Select Year's</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
        </select>
      </div>
      <br />
      <br />
      {loading && <div>A moment please...</div>}
      {error && (
        <p
          style={{
            fontSize: "20px",
            color: "red",
            fontFamily: "monospace",
            fontWeight: "900",
            textAlign: "center",
          }}
        >
          {error}
        </p>
      )}
      <table
        style={{
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        }}
        className="table table-success table-striped table-hover"
      >
        <thead>
          <tr>
            <th scope="col">Full Name</th>
            <th scope="col">Gender</th>
            <th scope="col">Designation</th>
            <th scope="col">Year Joined</th>
          </tr>
        </thead>
        <tbody>
          {sortedData &&
            sortedData.map(
              ({ _id, fullName, gender, joiningYear, designation }) => (
                <tr key={_id}>
                  <td>{fullName}</td>
                  <td>{gender}</td>
                  <td>{designation}</td>
                  <td>{joiningYear}</td>
                </tr>
              )
            )}
        </tbody>
      </table>
      <div
        className="voltFoot"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <img
          src={voltFoot}
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
          }}
          alt="Thank you our cool volunteers"
        />
      </div>
    </div>
  );
}

export default Volunteers;
