import React from 'react'
import { BrowserRouter ,Route, Routes } from "react-router-dom";

// importing every routes

import Home from './home'
// DD1
// import Aboutus from '../components/'
import Achivements from './aboutus/Achivements'
import AdvisorCommite from './aboutus/AdvisorCommite'
import Founder from './aboutus/Founders'
import Memmbers from './aboutus/Memmbers'
import Volunteers from './aboutus/Volunteers'
// DD2
// import Events from '../components/'
import Competition from './events/Competiton'
import OngoingEvent from './events/OngoingEvents'
import SuccessfulEvent from './events/SuccessfulEvents'
import UpcomingEvent from './events/UpcomingEvents'
// DD3
// import StudyContents from '../components'
import Article from './study contents/Articles'
import Mcqs from './study contents/Mcq'
// import Ppts from './study contents/Ppts'
import ResearchPapers from './study contents/ResearchPapers'
import McqTest from './fixed components/McqTest'
import Store from './fixed components/Store'
import Elibrary from './study contents/Elibrary'
import ElibCat from './study contents/ElibCat'
// DD4
import Contatctus from './ContactUs'
import Navbar from './fixed components/Navbar';
import Policies from './fixed components/Policies'
import FooterPage from './fixed components/FooterPage';
import ArticlePage from './fixed components/ArticlePage'
import NotFoundPage from './fixed components/NotFoundPage'
function RouteUrls() {
  return (<>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />


        {/* ------------------------------- */}

        <Route exact path="/achievements" element={<Achivements />} />
        <Route exact path="/advisor-committee" element={<AdvisorCommite />} />
        <Route exact path="/founders" element={<Founder />} />
        <Route exact path="/members" element={<Memmbers />} />
        <Route exact path="/volunteers" element={<Volunteers />} />
        {/* -------------------------- */}

        <Route exact path="/competitions" element={<Competition />} />
        <Route exact path="/ongoing-events" element={<OngoingEvent />} />
        <Route exact path="/successful-events" element={<SuccessfulEvent />} />
        <Route exact path="/upcoming-events" element={<UpcomingEvent />} />
        {/* ---------------------------- */}
        <Route exact path="/Mcqs" element={<Mcqs />} />
        <Route exact path="/McqTest/:id" element={<McqTest />} />
        <Route exact path="/researchPapers" element={<ResearchPapers />} />
        <Route exact path="/ArticlePage/:id" element={<ArticlePage />} />
        <Route exact path="/eLibrary" element={<Elibrary/>} /> 
        <Route exact path="/e/lib/category/catKey/:catValue" element ={<ElibCat/>} />


        {/* ------------------------------ */}
    <Route exact path="/Store" element={<Store/>} />
        <Route exact path="/articles" element={<Article />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route exact path="/contact-us" element={<Contatctus />} />
        <Route exact path="/policies" element={<Policies />} />
      </Routes>
      <FooterPage />
    </BrowserRouter>
  </>
  )
}

export default RouteUrls
