import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import "../css/mobileDesign.css";
// import {useRef } from "react";
function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg  navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/Home">
            <img
              src={logo}
              id="brandName"
              alt="Logo"
              style={{ width: "80px", height: "77px", margin: "2px" }}
            />
            <h1
              id="navTitle"
              style={{
                color: "red",
                margin: "auto auto auto 10px",
                fontFamily: "'Sofia', cursive",
                fontWeight: "400",
              }}
            >
              Applied Forensic Research Sciences
            </h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0 menuItem"
              style={{
                marginTop: "8px",
              }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link "
                  aria-current="page"
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                  to="/Home"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://appliedforensicresearchscience.blogspot.com/"
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                >
                  {" "}
                  Blog's
                </a>
              </li>
              <li className="nav-item">
                <Link
                  to="/Store"
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                  className="nav-link"
                  aria-current="page"
                >
                  Store
                </Link>
              </li>
              <li className="nav-item dropdown">
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle"
                  style={{
                    fontFamily: "pacifico",
                    background: "none",
                    borderStyle: "none",
                    padding: "3px",
                  }}
                  id="eventscrollingDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Events
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="eventscrollingDropdown"
                >
                  <li>
                    <Link to="/upcoming-events" className="navbar-item">
                      Upcoming Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/ongoing-events" className="navbar-item">
                      Ongoing Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/successful-events" className="navbar-item">
                      Completed Events
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  to="/competitions"
                  className="nav-link"
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                >
                  Competitions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Mcqs"
                  className="nav-link"
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                >
                  Quiz Test
                </Link>
              </li>

              {/*  */}
              <li className="nav-item dropdown">
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle"
                  style={{
                    fontFamily: "pacifico",
                    background: "none",
                    borderStyle: "none",
                    padding: "3px",
                  }}
                  id="studyMaterialScrollingDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Study Materials
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="studyMaterialScrollingDropdown"
                >
                  <li>
                    <Link to="/eLibrary" className="navbar-item">
                      E-Library
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://appliedforensicresearchscience.blogspot.com/search/label/practical"
                      className="navbar-item"
                    >
                      Practical Learning
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.youtube.com/channel/UCXvAKj7xDgXaBPOkG-wJKiw"
                      className="navbar-item"
                    >
                      Online Videos
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.udemy.com/course/afrs-e-learning-courcse/?kw=introduction+to+forens&src=sac&couponCode=NVDPRODIN35"
                      className="navbar-item"
                    >
                      Online Course
                    </a>
                  </li>

                  <li>
                    <Link to="/researchPapers" className="navbar-item">
                      Published Papers
                    </Link>
                  </li>

                  <li>
                    <Link to="/articles" className="navbar-item">
                      Articles
                    </Link>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://appliedforensicresearchscience.blogspot.com/search/label/short%20note/"
                      className="navbar-item"
                    >
                      Short Notes
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://appliedforensicresearchscience.blogspot.com/search/label/case%20study"
                      target="_blank"
                      rel="noreferrer"
                      className="navbar-item"
                    >
                      Case Studies
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.slideshare.net/RakeshMia"
                      target="_blank"
                      rel="noreferrer"
                      className="navbar-item"
                    >
                      PPT's
                    </a>
                  </li>
                </ul>
              </li>
              {/*  */}

              {/*  */}
              <li className="nav-item dropdown">
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle"
                  style={{
                    fontFamily: "pacifico",
                    background: "none",
                    borderStyle: "none",
                    padding: "3px",
                  }}
                  id="aboutScrollingDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About AFRS
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="aboutScrollingDropdown"
                >
                  <li>
                    <Link to="/achievements" className="navbar-item">
                      AFRS and its Achievements
                    </Link>
                  </li>
                  <li>
                    <Link to="/founders" className="navbar-item">
                      Founders
                    </Link>
                  </li>
                  <li>
                    <Link to="/members" className="navbar-item">
                      Members
                    </Link>
                  </li>
                  <li>
                    <Link to="/volunteers" className="navbar-item">
                      Volunteers
                    </Link>
                  </li>
                  <li>
                    <Link to="/advisor-committee" className="navbar-item">
                      Advisor Panel
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact-us"
                  className="nav-link "
                  style={{ color: "#fff", fontFamily: "pacifico" }}
                >
                  Contact-Us
                </Link>
              </li>
            </ul>

            <form className="d-flex mr-2 align-content-right">
              <button
                style={{
                  fontFamily: "Sofia",
                  background: "#e8f5e9",
                  border: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
                className="btn"
                onClick={() => window.open("https://nfsmuseum.com/", "_blank")}
              >
                <strong>AFRS Digital MuseUm</strong>
              </button>
            </form>

            <form className="d-flex align-content-right">
              <button
                style={{ fontFamily:"Sofia", background:"#e8f5e9"}}
                className="btn"
                type="submit"
              >
                <a
                  href="https://forms.gle/1uy6CimxLijEmC2EA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Join-Us</strong>
                </a>
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
